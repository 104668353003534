/* modal.css */

.popup {
    position: relative;
    width: 80%; /* Largura desejada */
    height: 60%; 
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: justify;
    justify-content: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  /* Estilo para a cena VR quando não está em tela cheia */
  .vr-scene {
    width: 85vw; /* Largura desejada */
    height: 88vh; /* Altura desejada */
    margin: 0 auto;
  }
  
  /* Estilo para a cena VR em tela cheia */
  .vr-scene.fullscreen {
    width: 85vw; /* Largura desejada */
    height: 88vh; /* Altura desejada */
    margin: 0 auto;
  }
  
  .vr-canvas-container {
    width: 85vw; /* Largura desejada */
    height: 88vh; /* Altura desejada */
    margin: 0 auto !important; /* Centralize a div horizontalmente, se desejar */
  }
  /* Resto do seu estilo CSS aqui */
  

  /* Adicione estilos à div que envolve o componente Dropzone */
.dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; /* Altura desejada */
  width: 100%; /* Largura desejada */
  border: 2px dashed #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px; /* Espaçamento inferior opcional */
}

/* Estilos para o texto dentro da Dropzone */
.dropzone p {
  margin: 0;
}

/* Adicione outros estilos conforme necessário para o restante do seu aplicativo */

/* Esconder o botao de deixar full screen */
.a-enter-vr-button {
  display: none;
}
